import styled from 'styled-components';
import { Spin } from 'antd';


export const StyledSpinner = styled(Spin)`
  &&& {
    z-index: 10;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const StyledSpinnerOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.currentModeConfig.contentBackground};
`;
